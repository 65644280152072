<template>
  <div
    class="d-flex flex-column"
    :style="{'min-height': 'calc(100vh) !important'}"
  >
    <v-row align="center" justify="center" no-gutters class="align-self-center">
      <h1>Error 404 la pagina que Busca no existe</h1>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Error404',
};
</script>

<style scoped></style>
